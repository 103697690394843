import {request} from '../../util/Request'

const PRIORITY_FORM_STATE = {
    orders: [],
    client_affected_priority: 1,
    client_affected_description: '',
    client_affected: true,
}

export default {
    data() {
        return {
            selectedOrders: [],
            groupedOrders: [],
            selectedOrderLength: 0,
            priorityFormVisible: false,
            priorityFormState: {...PRIORITY_FORM_STATE},
        }
    },
    methods: {
        handlePriority() {
            const self = this
            _.map(self.selectedOrders, (item) => {
                self.priorityFormState.orders.push({
                    id: item.id
                })
            })

            this.priorityFormVisible = true
        },
        async handlePrioritySubmit(client = false) {
            try {
                const response = await request({
                    url: 'orders/priority',
                    method: 'post',
                    data: {...this.priorityFormState},
                })

                this.itemUpdated()
                this.handlePriorityFormOperationClose(client)
            } catch (error) {
                this.handleServerError(error)
            }
        },
        handleLoadRowClick(item, rowIndex, event) {
            const index = _.findIndex(this.selectedOrders, {id: item.id});
            if (index >= 0) { // if already exist then delete
                setTimeout(() => this.$refs.orderTable.unselectRow(rowIndex), 200)
                delete this.selectedOrders[index]
                this.selectedOrderLength = this.selectedOrderLength - 1
            } else { // else add new
                this.selectedOrders.push(item)
                setTimeout(() => this.$refs.orderTable.selectRow(rowIndex), 200)
                this.selectedOrderLength = this.selectedOrderLength + 1
            }

            this.selectedOrders = _.compact(this.selectedOrders)
        },
        handlePriorityFormOperationClose(refreshList = true) {
            this.priorityFormState = {...PRIORITY_FORM_STATE}
            this.priorityFormState.orders.length = 0
            this.priorityFormVisible = false
            this.selectedOrders.length = 0
            this.selectedOrderLength = 0
            this.$refs.orderTable.clearSelected()

            if(refreshList) {
                this.refreshList()
            }
        },
    }
}
