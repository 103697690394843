import Error from '../../../util/Error'
import {request} from '../../../util/Request'

const ADDITIONAL_SERVICES_STATE = {
    token: null,
    id: null,
    is_old: 0,
    additional_service_id: null,
    description: null,
    error: false
};

const ADDITIONAL_SERVICE_FORM_STATE = {
    visible: false,
    id: null,
    additional_services: []
};

export default {
    data() {
        return {
            additionalServiceFormFields: {...ADDITIONAL_SERVICE_FORM_STATE},
            additional_service: {...ADDITIONAL_SERVICES_STATE},
            additional_services: [],
        }
    },
    methods: {
        async handleAdditionalServiceClick({supplier_compound, additional_services, id}) {
            this.dropdowns.suppliersAdditionalServices.length = 0
            this.additionalServiceFormFields = {...ADDITIONAL_SERVICE_FORM_STATE}

            try {
                const response = await request({
                    url: '/dropdowns/suppliers/additional/services',
                    method: "post",
                    data: {
                        supplier_compound_id: (supplier_compound ? supplier_compound.id : null)
                    }
                })

                const {data} = response
                this.dropdowns.suppliersAdditionalServices = data
                this.additionalServiceFormFields.id = id
                this.additionalServiceFormFields.visible = true
                this.additional_services = (additional_services) ? additional_services.map((item) => ({
                    id: item.token,
                    is_old: item.is_old,
                    token: item.token,
                    additional_service_id: item.additional_service.id,
                    description: item.description,
                    compound_notes: item.compound_notes,
                    marked_done_at: item.marked_done_at,
                    is_rejected: item.is_rejected,
                    error: false
                })) : []
            } catch (e) {
                this.dropdowns.suppliersAdditionalServices = []
            }
        },
        async handleAdditionalServiceSubmit() {
            try {
                const response = await request({
                    url: '/orders/client/additional/service',
                    method: 'post',
                    data: {...this.additionalServiceFormFields, additional_services: this.additional_services},
                })

                this.itemUpdated()
                this.handleAdditionalServiceOperationClose()
                this.loadList()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    this.validationError()
                    return false
                }

                this.handleServerError(error)
            }
        },
        handleAddUpdateAdditionalLocationClick() {
            this.additional_service.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.additional_service.additional_service_id) {
                this.additional_service.error = true;
                errors.additional_service_id = [this.$t('validation.required')];
            }

            _.map(this.additional_services, (location) => {
                if (location.token !== this.additional_service.token && !location.is_rejected) {
                    if ((location.additional_service_id === this.additional_service.additional_service_id)) {
                        errors.additional_service_id = [this.$t('validation.duplicate')];
                        this.additional_service.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if (this.additional_service.error) return false;

            const entity = this.additional_service;
            let index = -1;

            if (entity.token)
                index = _.findIndex(this.additional_services, {token: entity.token});

            if (this.additional_services[index] !== undefined) {
                this.additional_services[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                this.additional_services.push(entity)
            }

            this.additional_service = {
                ...ADDITIONAL_SERVICES_STATE,
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
        },
        handleDeleteAdditionalLocationClick(token) {
            const index = _.findIndex(this.additional_services, {token: token})
            if (this.additional_services[index] !== undefined) {
                this.additional_services.splice(index, 1);
            }
        },
        handleEditAdditionalLocationClick(token) {
            const index = _.findIndex(this.additional_services, {token: token})
            if (this.additional_services[index] !== undefined) {
                this.additional_service = {...this.additional_services[index]};
            }
        },
        resetAdditionalLocation() {
            this.additional_services = []
            this.additional_service = {...ADDITIONAL_SERVICES_STATE}
            this.formErrors = new Error({})
        },
        handleAdditionalServiceOperationClose() {
            this.resetAdditionalLocation();
            this.additionalServiceFormFields = {...ADDITIONAL_SERVICE_FORM_STATE}
        }
    }
}
