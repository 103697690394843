<template>
    <div v-if="detailLoad">
        <b-row>
            <b-col offset-md="2" md="8" offset-lg="2" lg="8" sm="12">
                <div class="card p-4">
                    <div>
                        <b-row>
                            <b-col lg="10" md="10" sm="12">
                                <h4>
                                    <span class="text-capitalize">{{$t('title.order')}}#{{detailLoad.order_id}} </span>
                                    <span><small><ol-status :status="detailLoad.load_status"></ol-status></small></span>
                                </h4>
                                <h6>
                                    <span class="text-secondary">
                                        {{$global.utcDateToLocalDate(detailLoad.created_at) }}
                                    </span>
                                </h6>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" class="text-right">
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleCancelClick"
                                          v-b-tooltip.hover :title="$t('button.title.back')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="mt-3">
                        <div class="box border rounded p-4">
                            <h4><span class="text-capitalize">{{$t('title.order')}}: </span></h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.importerNumber')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.invoice_number_importer}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.invoiceDateImporter')}}</span>
                                        </h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.invoice_date_importer}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-capitalize">{{$t('title.brand')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.brand ? detailLoad.brand.title : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-capitalize">{{$t('title.model')}}</span>
                                    </h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            {{detailLoad.model ? detailLoad.model.title : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12"><h6><span
                                        class="text-capitalize">{{$t('title.status')}}</span></h6></b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <ol-status :status="detailLoad.load_status"></ol-status>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.dealer')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.dealer">
                                            {{detailLoad.dealer.name}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.manufacturer')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.manufacturer">
                                            <template
                                                v-if="detailLoad.manufacturer.does_required_load_approval > 0">
                                                    <span :title="$t('title.pickupConfirmationRequired')"
                                                          v-b-tooltip.hover>{{detailLoad.manufacturer.name}}</span>
                                            </template>
                                            <template v-else>
                                                {{detailLoad.manufacturer.name}}
                                            </template>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.transportRequestAt')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.client_tr_at">
                                            {{detailLoad.client_tr_at ?
                                            $global.utcDateToLocalDate(detailLoad.client_tr_at) : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span
                                            class="text-capitalize">{{$t('title.transportRequestAcceptedAt')}}</span>
                                        </h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right" v-if="detailLoad.client_tr_accept_at">
                                            {{detailLoad.client_tr_accept_at ?
                                            $global.utcDateToLocalDate(detailLoad.client_tr_accept_at) : ''}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>

                    <div class="mt-3" v-for="(timeline, index) in detailLoad.order_operations">
                        <div class="box border rounded p-4" v-if="timeline.operation === 'inbound_load'">
                            <h4>
                                <span class="text-capitalize">{{$t('title.inboundLoad')}}: #{{timeline.inbound_load.load_number}} </span>
                            </h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.supplierCarrier')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(timeline.inbound_load.supplier_carrier || {}).name}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.supplierCompound')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(timeline.inbound_load.supplier_compound || {}).name}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.fromLocation')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"><print-ad titled="1" :item="timeline.inbound_load.from_location"></print-ad></div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.toLocation')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"><print-ad titled="1" :item="timeline.inbound_load.to_location"></print-ad></div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.driver')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(timeline.inbound_load.driver || {}).name}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.transportVehicle')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                            <span v-if="timeline.inbound_load.transport_vehicle">
                                                {{timeline.inbound_load.transport_vehicle.truck_plate_number}}
                                            </span>
                                            <span v-if="timeline.inbound_load.transport_vehicle">
                                                | {{timeline.inbound_load.transport_vehicle.trailer_plate_number}}
                                            </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->

                            </div>
                        </div>

                        <div class="box border rounded p-4" v-if="timeline.operation === 'cc_inbound_load'">
                            <h4>
                                <span class="text-capitalize">{{$t('title.inboundLoad')}}: CC#{{timeline.cc_inbound_load.load_number}} </span>
                            </h4>
                            <hr>
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.supplierCarrier')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">{{(timeline.cc_inbound_load.supplier_carrier || {}).name}}</div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.supplierCompound')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">{{(timeline.cc_inbound_load.supplier_compound || {}).name}}</div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.fromLocation')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right"><print-ad titled="1" :item="timeline.cc_inbound_load.from_location"></print-ad></div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.toLocation')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right"><print-ad titled="1" :item="timeline.cc_inbound_load.to_location"></print-ad></div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.driver')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">{{(timeline.cc_inbound_load.driver || {}).name}}</div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.transportVehicle')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">
                                            <span v-if="timeline.cc_inbound_load.transport_vehicle">
                                                {{(timeline.cc_inbound_load.transport_vehicle || {}).truck_plate_number}}
                                            </span>
                                        <span v-if="timeline.cc_inbound_load.transport_vehicle">
                                                | {{(timeline.cc_inbound_load.transport_vehicle || {}).trailer_plate_number}}
                                            </span>
                                    </div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                        </div>

                        <div class="box border rounded p-4" v-if="timeline.operation === 'spot_inbound_load'">
                            <h4>
                                <span class="text-capitalize">{{$t('title.inboundLoad')}}: CC#{{timeline.spot_inbound_load.load_number}} </span>
                            </h4>
                            <hr>
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.supplierCarrier')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">{{(timeline.spot_inbound_load.supplier_carrier || {}).name}}</div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.supplierCompound')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">{{(timeline.spot_inbound_load.supplier_compound || {}).name}}</div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.fromLocation')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right"><print-ad titled="1" :item="timeline.spot_inbound_load.from_location"></print-ad></div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.toLocation')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right"><print-ad titled="1" :item="timeline.spot_inbound_load.to_location"></print-ad></div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.driver')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">{{(timeline.spot_inbound_load.driver || {}).name}}</div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.transportVehicle')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">
                <span v-if="timeline.spot_inbound_load.transport_vehicle">
                    {{(timeline.spot_inbound_load.transport_vehicle || {}).truck_plate_number}}
                </span>
                                        <span v-if="timeline.spot_inbound_load.transport_vehicle">
                    | {{(timeline.spot_inbound_load.transport_vehicle || {}).trailer_plate_number}}
                </span>
                                    </div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->

                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.price')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">
                                        {{timeline.spot_inbound_load.price}} ({{timeline.spot_inbound_load.currency}})
                                    </div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                        </div>

                        <div class="box border rounded p-4" v-if="timeline.operation === 'outbound_load'">
                            <h4>
                                <span class="text-capitalize">{{$t('title.outboundLoad')}}: #{{timeline.outbound_load.load_number}} </span>
                            </h4>
                            <hr>
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.supplierCarrier')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(timeline.outbound_load.supplier_carrier || {}).name}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.supplierCompound')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(timeline.outbound_load.supplier_compound || {}).name}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.fromLocation')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"><print-ad titled="1" :item="timeline.outbound_load.from_location"></print-ad></div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.toLocation')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right"><print-ad titled="1" :item="timeline.outbound_load.to_location"></print-ad></div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.driver')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(timeline.outbound_load.driver || {}).name}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.transportVehicle')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">
                                                <span v-if="timeline.outbound_load.transport_vehicle">
                                                    {{timeline.outbound_load.transport_vehicle.truck_plate_number}}
                                                </span>
                                            <span v-if="timeline.outbound_load.transport_vehicle">
                                                    | {{timeline.outbound_load.transport_vehicle.trailer_plate_number}}
                                                </span>
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div>
                        </div>

                        <div class="box border rounded p-4" v-if="timeline.operation === 'reexport_load'">
                            <h4>
                                <span class="text-capitalize">{{$t('title.reexport')}}: #{{timeline.reexport_load.load_number}} </span>
                            </h4>
                            <hr>
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.supplierCarrier')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">{{(timeline.reexport_load.supplier_carrier || {}).name}}</div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.supplierCompound')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">{{(timeline.reexport_load.supplier_compound || {}).name}}</div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.fromLocation')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right"><print-ad titled="1" :item="timeline.reexport_load.from_location"></print-ad></div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.toLocation')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right"><print-ad titled="1" :item="timeline.reexport_load.to_location"></print-ad></div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.driver')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">{{(timeline.reexport_load.driver || {}).name}}</div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.transportVehicle')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">
                                            <span v-if="timeline.reexport_load.transport_vehicle">
                                                {{timeline.reexport_load.transport_vehicle.truck_plate_number}}
                                            </span>
                                        <span v-if="timeline.reexport_load.transport_vehicle">
                                                | {{timeline.reexport_load.transport_vehicle.trailer_plate_number}}
                                            </span>
                                    </div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.price')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">
                                        {{timeline.reexport_load.price}} ({{timeline.reexport_load.currency}})
                                    </div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                        </div>

                        <div class="box border rounded p-4" v-if="timeline.operation === 'dealer_to_dealer_load'">
                            <h4>
                                <span class="text-capitalize">{{$t('title.dealerToDealerLoad')}}: #{{timeline.dtd_load.load_number}} </span>
                            </h4>
                            <hr>
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.supplierCarrier')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">{{(timeline.dtd_load.supplier_carrier || {}).name}}</div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.dealer')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">{{(timeline.dtd_load.dealer || {}).name}}</div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.fromLocation')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right"><print-ad titled="1" :item="timeline.dtd_load.from_location"></print-ad></div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.toLocation')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right"><print-ad titled="1" :item="timeline.dtd_load.to_location"></print-ad></div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.driver')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">{{(timeline.dtd_load.driver || {}).name}}</div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.transportVehicle')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12">
                                    <div class="text-right">
                                            <span v-if="timeline.dtd_load.transport_vehicle">
                                                {{timeline.dtd_load.transport_vehicle.truck_plate_number}}
                                            </span>
                                        <span v-if="timeline.dtd_load.transport_vehicle">
                                                | {{timeline.dtd_load.transport_vehicle.trailer_plate_number}}
                                            </span>
                                    </div>
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                            <b-row>
                                <b-col md="6" lg="6" sm="12">
                                    <h6><span class="text-capitalize">{{$t('title.price')}}</span></h6>
                                </b-col><!--/.col-->
                                <b-col md="6" lg="6" sm="12" class="text-right">
                                    {{timeline.dtd_load.price}} ({{timeline.dtd_load.currency}})
                                </b-col><!--/.col-->
                            </b-row><!--/.row-->
                        </div>
                    </div>

                    <div class="box border rounded p-4">
                        <b-tabs content-class="mt-3" fill>
                            <b-tab :title="$t('title.additionalServices')" active>
                                <div class="mt-3" v-if="detailLoad.additional_services">
                                    <div class="clearfix">
                                        <table class="table table-bordered table-striped">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{{$t('column.additionalService')}}</th>
                                                <th>{{$t('column.description')}}</th>
                                                <th>{{$t('column.compoundNote')}}</th>
                                                <th>ETR</th>
                                                <th>{{$t('column.status')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(service,index) in detailLoad.additional_services">
                                                <td>{{index + 1}}</td>
                                                <td>{{service.additional_service.title}}</td>
                                                <td>{{service.description}}</td>
                                                <td>{{service.compound_notes}}</td>
                                                <td>{{service.estimated_time_repair ?
                                                    $global.dateFormat(service.estimated_time_repair): ''}}
                                                </td>
                                                <td>
                                                        <span v-if="service.is_rejected">
                                                            {{ $t('title.rejected') }}
                                                        </span>
                                                    <span v-else>
                                                            {{(!(!service.marked_done_at)) ? $t('title.done') : $t('title.pending') }}
                                                        </span>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <tfoot
                                                v-if="detailLoad.additional_services && detailLoad.additional_services.length == 0">
                                            <tr>
                                                <td colspan="6">{{$t('title.noDataAvailable')}}</td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div><!--/.box-->
                            </b-tab>
                            <b-tab :title="$t('title.damageHistory')">
                                <div class="mt-3" v-if="detailLoad.damage_histories">
                                    <div class="clearfix">
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-striped">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{{$t('column.creator')}}</th>
                                                    <th>ETR</th>
                                                    <th>{{$t('column.controllerName')}}</th>
                                                    <th>{{$t('column.comments')}}</th>
                                                    <th>{{$t('column.attachment')}}</th>
                                                    <th>{{$t('column.createdAt')}}</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(damage, index) in detailLoad.damage_histories">
                                                    <td>{{index + 1}}</td>
                                                    <td>{{damage.author}}</td>
                                                    <td>{{damage.etr ? $global.utcDateToLocalDate(damage.etr,
                                                        'DD.MM.YYYY', 'YYYY-MM-DD'): ''}}
                                                    </td>
                                                    <td>{{damage.controller_name}}</td>
                                                    <td>{{damage.comments}}</td>
                                                    <td><a v-if="damage.order_damage_attachments.length" v-b-modal.files-attachments
                                                           @click="getAttachmentItem(damage.order_damage_attachments,$t('title.damageHistoriesAttachment'))">
                                                        <i class="fe fe-paperclip"></i>Attachments</a>
                                                        <a v-else href="javascript:;">No Attachment</a>
                                                    </td>
                                                    <td>{{damage.created_at ?
                                                        $global.utcDateToLocalDate(damage.created_at): ''}}
                                                    </td>

                                                </tr>
                                                </tbody>
                                                <tfoot
                                                    v-if="detailLoad.damage_histories && detailLoad.damage_histories.length == 0">
                                                <tr>
                                                    <td colspan="7">{{$t('title.noDataAvailable')}}</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div><!--/.box-->
                            </b-tab>
                        </b-tabs>
                    </div>

                    <div class="mt-3" v-if="detailLoad.load_status_Histories">
                        <div class="box border rounded p-4">
                            <h4>
                                <span class="text-capitalize">{{$t('title.history')}}</span>
                            </h4>
                            <hr>
                            <div class="clearfix">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th width="120">{{$t('title.status')}}</th>
                                        <th width="550">{{$t('title.comments')}}</th>
                                        <th width="200">{{$t('title.dateTime')}}</th>
                                        <th width="200">{{$t('title.updatedBy')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-for="(history, index) in detailLoad.load_status_Histories">
                                        <template v-if="history.status == 8">
                                            <tr v-if="detailLoad.client_tr_accept_at">
                                                <td>{{ $t('title.readyToBeLoaded') }}</td>
                                                <td>{{ detailLoad.transport_request.supplier_notes }}</td>
                                                <td>{{ $global.utcDateToLocalDate(detailLoad.client_tr_accept_at)
                                                    }}
                                                </td>
                                                <td>{{ (detailLoad.supplier_compound) ?
                                                    detailLoad.supplier_compound.name : '' }}
                                                </td>
                                            </tr>
                                            <tr v-if="detailLoad.client_tr_at">
                                                <td>{{$t('title.deliveryRequest')}}</td>
                                                <td>{{detailLoad.transport_request.client_notes}}</td>
                                                <td>{{ $global.utcDateToLocalDate(detailLoad.client_tr_at) }}</td>
                                                <td>{{ (detailLoad.client) ? detailLoad.client.company_name : ''
                                                    }}
                                                </td>
                                            </tr>
                                        </template>
                                        <tr>
                                            <td>{{history._status}}</td>
                                            <td>{{history.comments}}</td>
                                            <td>{{ $global.utcDateToLocalDate(history.created_at) }}</td>
                                            <td>{{history.author}}</td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table><!--/.table.table-striped -->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>
                </div><!--/.card-->
            </b-col><!--/.col-->
        </b-row><!--/.row-->
        <!--  attachment modal-->
        <b-modal id="files-attachments" ok-only :title="selectedAttachmentTitle" ok-title="Cancel">
            <div class="table-responsive">
                <table class="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>{{$t('column.attachment')}}</th>
                        <th>{{$t('column.download')}}</th>
                        <th>{{$t('column.createdAt')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(file,index) in selectedAttachment">
                        <td>{{index+1}}</td>
                        <td>{{file.title}}.{{file.document.extension}}</td>
                        <td>
                            <a :title="$t('button.download')"
                               v-b-tooltip.hover variant="outline-primary"
                               class="mb-1"
                               v-if="file.document && file.document.download_url"
                               :disabled="!(file.document && file.document.download_url)"
                               :href="(file.document ? file.document.download_url : '')"
                               target="_blank">
                                <i class="fe fe-download"></i>
                            </a>
                        </td>
                        <td>
                            {{file.document.created_at ? $global.utcDateToLocalDate(file.document.created_at):
                            ''}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </div><!--/div-->
</template>
<script>
    import {request} from "../../../util/Request";

    export default {
        props: ['id', 'handleCloseOperation'],
        data() {
            return {
                detailLoad: null,
                items: [],
                selectedAttachment:[],
                selectedAttachmentTitle:null,
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
                this.getDetail(this.$route.query.oToken)
            } else {
                this.serverError()
            }
        },
        methods: {
            getAttachmentItem(item,title) {
                this.selectedAttachment = item
                this.selectedAttachmentTitle = title
            },
            async getDetail(id) {
                await request({
                    url: `/orders/client/detail/${id}`,
                })
                    .then((response) => {
                        const {data} = response
                        this.detailLoad = data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            handleCancelClick() {
                this.handleCloseOperation()
            }
        }
    }
</script>
